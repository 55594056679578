<template>
    <a-popover 
        :trigger="['hover']" 
        destroyTooltipOnHide
        placement="bottomRight">
        <div class="action_btn">
            <a-button
                type="link"
                class="text_current">
                <i class="fi fi-rr-apps"></i>
            </a-button>
        </div>
        <div slot="content">
            <draggable 
                v-model="routersList"
                :forceFallback="true"
                ghost-class="ghost"
                :class="dragging && 'dragging'"
                class="grid grid-cols-4 gap-1 grid-rows-1 p-2 m_sc"
                draggable=".a_i"
                group="aside_menu"
                :options="{delay: 300}"
                @start="dragging = true"
                @end="dragging = false">
                <router-link 
                    v-for="item in routersList" 
                    :key="item.name" 
                    class="h_m_i a_i"
                    :to="{ name: item.name }">
                    <MenuItem :item="item" />
                </router-link>
            </draggable>
        </div>
    </a-popover>
</template>

<script>
import { mapState } from 'vuex'
import MenuItem from './MenuItem.vue'
import draggable from "vuedraggable"
export default {
    components: {
        MenuItem,
        draggable
    },
    computed: {
        ...mapState({
            routers: state => state.navigation.routerList
        }),
        routersList: {
            get() {
                return this.routers.filter(f => f.isShow)
            },
            set(val) {
                this.$store.dispatch('navigation/changeRouterList', val)
            }
        }
    },
    data() {
        return {
            dragging: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.h_m_i{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #354052;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: var(--borderRadius);
    padding: 8px 10px;
    &:hover{
        color: var(--blue);
        background: #f9f9f9;
    }
    &.router-link-active{
        color: var(--blue);
    }
}
.m_sc{
    &.dragging{
        .h_m_i{
            &::v-deep{
                .icon{
                    animation-delay: -0.65; 
                    animation-duration: .20s 
                }
            }
            &:nth-child(2n) {
                &::v-deep{
                    .icon{
                        animation-name: JiggleEffect;
                        animation-iteration-count: infinite;
                        transform-origin: 50% 10%;
                        -webkit-transition: all .2s ease-in-out;
                    }
                }
            }
            &:nth-child(2n-1) {
                &::v-deep{
                    .icon{
                        animation-name: JiggleEffect2;
                        animation-iteration-count: infinite;
                        animation-direction: alternate;
                        transform-origin: 30% 5%;
                    }
                }
            }
        }
    }
}
</style>